import React from "react";
import InternalDoors from "./InternalDoors";
import "./Doors.css";
import ExternalDoors from "./ExternalDoors";

function Doors() {
  return (
    <>
      <section id="door">
        <div className="doorContainer">
          <div className="internalDoorBanner">
            <div className="doorTitle">
              <span>INTERIOR DOORS</span>
            </div>
          </div>
          <InternalDoors />
          <div className="externalDoorBanner">
            <div className="doorTitle">
              <span>EXTERIOR DOORS</span>
            </div>
          </div>
          <ExternalDoors />
        </div>
      </section>
    </>
  );
}

export default Doors;
