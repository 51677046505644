import React from "react";

function KitchenCard() {
  return (
    <>
      <section id="kitchens">
        <div className="kitchensContainer">
          <div className="cardLeftContainer">
            <img
              className="cardLeftTopImg"
              src="/img/IMG-20240403-WA0012.jpg"
              alt="1"
            />
            <div className="cardContent">
              <span>Sibella</span>
            </div>
            <div className="cardLine" />
            <img
              className="cardRightImg"
              src="/img/1712132877575.jpg"
              alt="6"
            />
            <div className="cardContent">
              <span>Carla</span>
            </div>
            <div className="cardLine" />
          </div>
          <div className="cardRightContainer">
            <div className="cardLineTop" />
            <div className="cardContent">
              <span>Acapella</span>
            </div>
            <img
              className="cardRightImg"
              src="/img/1712132877569.jpg"
              alt="6"
            />
            <div className="cardLine" />
          </div>
        </div>
      </section>
    </>
  );
}

export default KitchenCard;
