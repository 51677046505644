import React from "react";
import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import CompanyMainInfo from "../../CompanyMainInfo/CompanyMainInfo";
import "./Aboutus.css";

function Aboutus() {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <section id="aboutus">
          <div className="aboutusContainer">
            <div className="aboutusBanner">
              <div className="aboutusTitle">ABOUT US</div>
            </div>
            <CompanyMainInfo />
            <div className="aboutusImgContainer">
              <img
                className="aboutusImg1"
                src="/img/IMG-20240213-WA0001.jpg"
                alt="1"
              />
              <img
                className="aboutusImg2"
                src="/img/1712132877575.jpg"
                alt="2"
              />
              <img
                className="aboutusImg3"
                src="/img/ironmongeryHome.jpg"
                alt="3"
              />
              <img
                className="aboutusImg2"
                src="/img/1712132877592.jpg"
                alt="4"
              />
              <img
                className="aboutusImg1"
                src="/img/1713112315681(cropped).jpg"
                alt="5"
              />
            </div>
            <div className="aboutusProjectContainer">
              <div className="yearsInInd">
                <h1 className="num">
                  {counterOn && (
                    <CountUp start={0} end={20} duration={10} delay={0} />
                  )}
                  +
                </h1>
                <span className="aboutusProjectContent">
                  years in the industry
                </span>
              </div>
              <div className="finishedProj">
                <h1 className="num">
                  {counterOn && (
                    <CountUp start={0} end={1500} duration={10} delay={0} />
                  )}
                  +
                </h1>
                <span className="aboutusProjectContent">finished projects</span>
              </div>
              <div className="satisfiedClients">
                <h1 className="num">
                  {counterOn && (
                    <CountUp start={0} end={1500} duration={10} delay={0} />
                  )}
                  +
                </h1>
                <span className="aboutusProjectContent">satisfied clients</span>
              </div>
            </div>
            <div className="deliveryProcessContainer">
              <div className="deliveryProcessTitle">
                <div className="titleDash" />
                <span>HOW IT WORKS</span>
                <div className="titleDash" />
              </div>
              <div className="deliveryProcessContent">
                <div className="deliveryProcess">
                  <div className="deliveryProcessCard">
                    <i className="process fa-solid fa-file-signature" />
                    <span>Consultation</span>
                  </div>
                  <i className="arrow fa-solid fa-arrow-right" />
                  <div className="deliveryProcessCard">
                    <i className="process fa-solid fa-compass-drafting" />
                    <span>Specification</span>
                  </div>
                  <i className="arrow fa-solid fa-arrow-right" />
                  <div className="deliveryProcessCard">
                    <i className="process fa-regular fa-clock" />
                    <span>Production</span>
                  </div>
                  <i className="arrow fa-solid fa-arrow-right" />
                  <div className="deliveryProcessCard">
                    <i className="process fa-solid fa-truck-fast" />
                    <span>Delivery/Installation</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScrollTrigger>
    </>
  );
}

export default Aboutus;
