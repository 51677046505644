import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <>
      <section id="footer">
        <div className="footerContainer">
          <div className="footerMobileContainer">
            <div className="leftFooter">
              <span className="footerAddressTitle">Location:</span>

              <span className="footerEmailTitle">Email:</span>

              <span className="footerTelTitle">Tel:</span>
            </div>
            <div className="centerFooter">
              <div className="footerAddress">
                Plot 9, Block 113, Beulah Land <br />
                Plaza, Lekki-Epe Expressway,
                <br /> Lekki, Lagos, Nigeria
              </div>

              <div className="footerEmail">info@bmdirect.biz</div>

              <div className="footerTel">
                +2349055194403
                <br />
                +2349055194415 <br />
                +2349055194413
              </div>
            </div>
          </div>
          <div className="rightFooter">
            <div className="footerSocials">
              <span className="socialsTitle">Socials:</span>
              <NavLink
                to="https://www.instagram.com/bmdirect_nigeria/"
                className="insta"
              >
                <i className="bi bi-instagram"></i>
              </NavLink>
              <NavLink
                to="https://web.facebook.com/BMdirectnigeria"
                className="facebook"
              >
                <i className="bi bi-facebook"></i>
              </NavLink>
              <NavLink
                to="https://www.linkedin.com/in/bmdirect-pointedge-386a4b162/"
                className="linkedin"
              >
                <i className="bi bi-linkedin"></i>
              </NavLink>
            </div>
            <br />
            <span className="socialsTitle">
              Subscribe to our newsletter to get news and latest releases:
            </span>
            <br />
            <form
              className="newsletterContainer"
              title="Please fill out this field"
              name="NewsLetter"
              action="https://formsubmit.co/sales@bmdirect.biz"
              method="post"
            >
              <div className="input">
                <input
                  type="email"
                  name="Email(Newsletter)"
                  id="emailSub"
                  placeholder="Enter Email Address"
                />
                <button type="submit" id="subSubmit">
                  <h4 id="subSubmitTxt">Subscribe</h4>
                </button>
              </div>
            </form>
            <div className="copyright">
              ©2024, BMDirect All Rights Reserved.
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
