import React from "react";

function Hinges() {
  return (
    <>
      <section id="hinges">
        <div className="hingesContainer">
          <div className="cardLeftContainer">
            <img
              className="cardLeftTopImg"
              src="/img/IMG-20240229-WA0006.jpg"
              alt="1"
            />
            <div className="cardLine" />
          </div>
          <div className="cardRightContainer">
            <div className="cardLineTop" />
            <img
              className="cardRightImg"
              src="/img/IMG-20240229-WA0011.jpg"
              alt="5"
            />
            <div className="cardLine" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Hinges;
