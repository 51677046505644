import React from "react";
import { NavLink } from "react-router-dom";

import "./HomeCards.css";

function HomeCards() {
  return (
    <>
      <section id="homeCard">
        <div className="homeCardContainer">
          <NavLink to="/doors">
            <div className="homeCard1">
              <div className="homeCardWriteUp">
                <span>Explore Our Doors</span>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="homeCardContainer">
          <NavLink to="/kitchens">
            <div className="homeCard2">
              <div className="homeCardWriteUp">
                <span>Explore Our Kitchens</span>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="homeCardContainer">
          <NavLink to="/wardrobes">
            <div className="homeCard3">
              <div className="homeCardWriteUp">
                <span>Explore Our Wardrobes</span>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="homeCardContainer">
          <NavLink to="/ironmongery">
            <div className="homeCard4">
              <div className="homeCardWriteUp">
                <span>Explore Our Ironmongery</span>
              </div>
            </div>
          </NavLink>
        </div>
      </section>
    </>
  );
}

export default HomeCards;
