import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop/scrollToTop";

import "./App.css";

//pages
import Home from "./components/pages/Home/Home";
import Contactus from "./components/pages/Contactus/Contactus";
import Doors from "./components/pages/Doors/Doors";
import Kitchens from "./components/pages/Kitchens/Kitchens";
import Wardrobes from "./components/pages/Wardrobes/Wardrobes";
import Aboutus from "./components/pages/Aboutus/Aboutus";
import Nav from "./components/Nav/Nav";
import Ironmongery from "./components/pages/Ironmongery/Ironmongery";
import Footer from "./components/Footer/Footer";
import Projects from "./components/pages/Projects/Projects";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/doors" element={<Doors />} />
        <Route path="/kitchens" element={<Kitchens />} />
        <Route path="/wardrobes" element={<Wardrobes />} />
        <Route path="/ironmongery" element={<Ironmongery />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/aboutus" element={<Aboutus />} />
      </Route>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

const Root = () => {
  return (
    <>
      <ScrollToTop />
      <Nav />
      <Outlet />
      <Footer />
    </>
  );
};

export default App;
