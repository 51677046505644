import React from "react";
import "./CompanyMainInfo.css";

function CompanyMainInfo() {
  return (
    <>
      <section>
        <div className="companyMainInfoContainer">
          <div className="companyMainInfo">
            <div className="companyMainInfoTitle">
              Nigeria's leading manufacturer of wood and steel doors, wardrobes
              and kitchens.
            </div>
            <span>
              BMDirect is Nigeria's leading manufacturer of wood and steel
              doors, wardrobes and kitchen cabinets. We have been developing,
              constantly improving, supplying and installing an extensive range
              of interior and exterior bespoke doors, kitchens and wardrobes to
              compliment the development of private and commercial buildings.
              Our collection includes interior and exterior doors made from
              steel, wood, toughened glass, aluminium and stainless steel.
            </span>
          </div>
        </div>
      </section>
    </>
  );
}

export default CompanyMainInfo;
