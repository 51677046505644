import React from "react";
import "./Home.css";
import { motion as m } from "framer-motion";
import HomeCarousel from "./HomeCarousel/HomeCarousel";
import HomeCards from "./HomeCards/HomeCards";
import { NavLink } from "react-router-dom";
import CompanyMainInfo from "../../CompanyMainInfo/CompanyMainInfo";
import HomePatners from "./HomePatners/HomePatners";
import { Carousel } from "react-bootstrap";

function Home() {
  return (
    <>
      <section id="home">
        <img
          className="homeBanner"
          src="/img/1712132877564.jpg"
          alt="Home Banner"
        />
        <img
          className="homeBanner"
          src="/img/1712132877592.jpg"
          alt="Home Banner"
        />
        <img
          className="homeBanner"
          src="/img/1713112315672.jpg"
          alt="Home Banner"
        />
        <Carousel
          fade={true}
          indicators={false}
          controls={false}
          className="homeBannerCarousel"
        >
          <Carousel.Item className="homeBannerCarouselItem">
            <img
              className="homeBannerCarouselImg"
              src="/img/1712132877564.jpg"
              alt="Home Banner"
            />
          </Carousel.Item>
          <Carousel.Item className="homeBannerCarouselItem">
            <img
              className="homeBannerCarouselImg"
              src="/img/1712132877592.jpg"
              alt="Home Banner"
            />
          </Carousel.Item>
          <Carousel.Item className="homeBannerCarouselItem">
            <img
              className="homeBannerCarouselImg"
              src="/img/1713112315672.jpg"
              alt="Home Banner"
            />
          </Carousel.Item>
        </Carousel>
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3, ease: "easeInOut" }}
          className="homeWriteupTitle"
        >
          <span>
            Nigeria's leading manufacturer <br />
            of doors, wardrobes <br />
            and kitchens.
          </span>
          <div className="homeMoreContainer">
            <NavLink to="/aboutus" className="homeMoreBtn">
              Learn More &nbsp;
              <i className="fa-solid fa-arrow-right" />
            </NavLink>
          </div>
        </m.div>
      </section>
      <CompanyMainInfo />
      <HomeCarousel />
      <HomeCards />
      <HomePatners />
    </>
  );
}

export default Home;
