import React from "react";

function InternalDoors() {
  return (
    <>
      <section id="doors">
        <div className="doorsContainer">
          <div className="cardLeftContainer">
            <img
              className="cardLeftTopImg"
              src="/img/IMG-20240213-WA0000.jpg"
              alt="1"
            />
            <div className="cardContent">
              <span>FLUSH DOOR</span>
              <p>
                Solid core flush door complete with frame and archtraves
                including <br />
                ironmongery and finished in high pressure laminate in the
                following sizes: 900mm x 2100mm, <br />
                900mm x 2400mm, 1000mm x 2400mm.
              </p>
            </div>
            <div className="cardLine" />
            <img
              className="cardLeftImg"
              src="/img/IMG-20240213-WA0001.jpg"
              alt="2"
            />

            <div className="cardContent">
              <span>FLUSH DOOR WITH VISION PANEL</span>
              <p>
                Solid core flush door complete with vision panel, frame and
                archtraves <br />
                including ironmongery and finished in high pressure laminate in
                the following sizes: <br />
                900mm x 2100mm, 900mm x 2400mm, 1000mm x 2400mm.
              </p>
            </div>
            <div className="cardLine" />
          </div>
          <div className="cardRightContainer">
            <div className="cardLineTop" />
            <div className="cardContent">
              <span>HIGH PRESSURED LAMINATE DOOR</span>
              <p>
                Solid core flush door complete with frame and archtraves
                including <br />
                ironmongery and finished in high pressure laminate in the
                following sizes: 900mm x 2100mm, <br />
                900mm x 2400mm, 1000mm x 2400mm.
              </p>
            </div>
            <img
              className="cardRightImg"
              src="/img/IMG-20240213-WA0004.jpg"
              alt="5"
            />
            <div className="cardLine" />
            <div className="cardContent">
              <span>FLUSH DOOR</span>
              <p>
                Solid core flush door complete with frame and archtraves
                including <br />
                ironmongery and finished in high pressure laminate in the
                following sizes: 900mm x 2100mm, <br />
                900mm x 2400mm, 1000mm x 2400mm.
              </p>
            </div>
            <img
              className="cardRightImg"
              src="/img/IMG-20240213-WA0002.jpg"
              alt="6"
            />
            <div className="cardLine" />
          </div>
        </div>
      </section>
    </>
  );
}

export default InternalDoors;
