import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Projects.css";

function Projects() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section id="projects">
        <div className="projectsBanner">
          <div className="projectsTitle">PROJECTS</div>
        </div>
        <div className="projectsContainer">
          <div className="projectsContent">
            <div className="projectsContentContainer">
              <div className="projectsWriteupContainer">
                <div className="projectsWriteupLeft">
                  <div className="projectsName">EVERCARE HOSPITAL</div>
                  <div className="projectsLocation">
                    1, Admiralty Way, Bisola Durosini Etti Dr, <br /> Lekki
                    Phase 1, Lagos
                  </div>
                  <br />
                </div>
                <div className="projectsWriteupRight">
                  <a href="/img/IMG-20240212-WA0000.jpg">
                    <img
                      className="projectsImg"
                      src="/img/IMG-20240212-WA0000.jpg"
                      alt="1"
                    />
                  </a>
                </div>
              </div>
              <Carousel className="projectsCarousel" responsive={responsive}>
                <a href="/img/IMG-20240212-WA0001.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240212-WA0001.jpg"
                    alt="2"
                  />
                </a>
                <a href="/img/IMG-20240212-WA0002.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240212-WA0002.jpg"
                    alt="3"
                  />
                </a>
                <a href="/img/IMG-20240212-WA0012.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240212-WA0012.jpg"
                    alt="3"
                  />
                </a>
                <a href="/img/IMG-20240212-WA0013.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240212-WA0013.jpg"
                    alt="3"
                  />
                </a>
                <a href="/img/IMG-20240212-WA0014.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240212-WA0014.jpg"
                    alt="3"
                  />
                </a>
              </Carousel>
            </div>
            <div className="projectsContentContainer">
              <div className="projectsWriteupContainer">
                <div className="projectsWriteupLeft">
                  <div className="projectsName">Regal Court</div> <br />
                  <div className="projectsLocation">
                    4, Olu Holloway Road, <br /> Ikoyi, Lagos
                  </div>
                </div>
                <div className="projectsWriteupRight">
                  <a href="/img/IMG-20240223-WA0000.jpg">
                    <img
                      className="projectsImg"
                      src="/img/IMG-20240223-WA0000.jpg"
                      alt="1"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="projectsContentContainer">
              <div className="projectsWriteupContainer">
                <div className="projectsWriteupLeft">
                  <div className="projectsName">Pan Atlantic University</div>
                  <div className="projectsLocation">
                    Lekki-Epe Expressway, <br />
                    Ibeju-Lekki, Lagos
                  </div>
                </div>
                <div className="projectsWriteupRight">
                  <a href="/img/IMG-20240322-WA0001.jpg">
                    <img
                      className="projectsImg"
                      src="/img/IMG-20240322-WA0001.jpg"
                      alt="1"
                    />
                  </a>
                </div>
              </div>
              <Carousel className="projectsCarousel" responsive={responsive}>
                <a href="/img/IMG-20240322-WA0002.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240322-WA0002.jpg"
                    alt="2"
                  />
                </a>
                <a href="/img/IMG-20240322-WA0004.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240322-WA0004.jpg"
                    alt="2"
                  />
                </a>
                <a href="/img/IMG-20240322-WA0003.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240322-WA0003.jpg"
                    alt="2"
                  />
                </a>
              </Carousel>
            </div>
            <div className="projectsContentContainer">
              <div className="projectsWriteupContainer">
                <div className="projectsWriteupLeft">
                  <div className="projectsName">Atlantic Edge</div> <br />
                  <div className="projectsLocation">Ikoyi, Lagos</div>
                </div>
                <div className="projectsWriteupRight">
                  <a href="/img/atlanticEdge.png">
                    <img
                      className="projectsImg"
                      src="/img/atlanticEdge.png"
                      alt="1"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="projectsContentContainer">
              <div className="projectsWriteupContainer">
                <div className="projectsWriteupLeft">
                  <div className="projectsName">Au-Monde Residences</div>
                  <div className="projectsLocation">Ikoyi, Lagos</div>
                </div>
                <div className="projectsWriteupRight">
                  <a href="/img/Screenshot_20231111_184119_Google.jpg">
                    <img
                      className="projectsImg"
                      src="/img/Screenshot_20231111_184119_Google.jpg"
                      alt="1"
                    />
                  </a>
                </div>
              </div>
              <Carousel className="projectsCarousel" responsive={responsive}>
                <a href="/imgScreenshot_20231111_183545_Google.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/Screenshot_20231111_183545_Google.jpg"
                    alt="2"
                  />
                </a>
                <a href="/img/Screenshot_20231111_183833_Google.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/Screenshot_20231111_183833_Google.jpg"
                    alt="2"
                  />
                </a>
              </Carousel>
            </div>
            <div className="projectsContentContainer">
              <div className="projectsWriteupContainer">
                <div className="projectsWriteupLeft">
                  <div className="projectsName">Lagos Business School</div>
                  <div className="projectsLocation">
                    Lekki-Epe Expressway, <br />
                    Sangotedo, Lagos
                  </div>
                </div>
                <div className="projectsWriteupRight">
                  <a href="/img/IMG-20240212-WA0003.jpg">
                    <img
                      className="projectsImg"
                      src="/img/IMG-20240212-WA0003.jpg"
                      alt="1"
                    />
                  </a>
                </div>
              </div>
              <Carousel className="projectsCarousel" responsive={responsive}>
                <a href="/img/IMG-20240223-WA0002.jpg">
                  <img
                    className="projectsCarouselImg"
                    src="/img/IMG-20240223-WA0002.jpg"
                    alt="2"
                  />
                </a>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Projects;
