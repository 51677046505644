import React from "react";
import "./Ironmongery.css";
import Handles from "./Handles";
import LockNKeys from "./LockNKeys";
import Hinges from "./Hinges";

function Ironmongery() {
  return (
    <>
      <section id="ironmongery">
        <div className="ironmongeryContainer">
          <div className="handleBanner">
            <div className="handleTitle">
              <span>HANDLES</span>
            </div>
          </div>
          <Handles />
          <div className="locknkeyBanner">
            <div className="locknkeyTitle">
              <span>LOCKS & KEYS</span>
            </div>
          </div>
          <LockNKeys />
          <div className="hingeBanner">
            <div className="hingeTitle">
              <span>HINGES</span>
            </div>
          </div>
          <Hinges />
        </div>
      </section>
    </>
  );
}

export default Ironmongery;
