import React from "react";

function LockNKeys() {
  return (
    <>
      <section id="locknkeys">
        <div className="locknkeysContainer">
          <div className="cardLeftContainer">
            <img
              className="cardLeftTopImg"
              src="/img/IMG-20240229-WA0005.jpg"
              alt="1"
            />
            <div className="cardLine" />
          </div>
        </div>
      </section>
    </>
  );
}

export default LockNKeys;
