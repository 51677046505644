import React from "react";
import "./HomePatners.css";

function HomePatners() {
  return (
    <>
      <section id="homePatners">
        <div className="homePatnersContainer">
          <div className="homePatnersTitle">
            <div className="homePatnersTitleDash" />
            <span>OUR PATNERS</span>
            <div className="homePatnersTitleDash" />
          </div>
          <marquee direction="left" loop="infinite">
            <div className="homePatnersImgContainer">
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0002.jpg"
                alt="1"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0007.jpg"
                alt="2"
              />
              <img
                className="homePatnersImg"
                src="/img/MicrosoftNg.jpg"
                alt="3"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0005.jpg"
                alt="4"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0006.jpg"
                alt="5"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0003.jpg"
                alt="6"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0008.jpg"
                alt="7"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0009.jpg"
                alt="8"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0010.jpg"
                alt="9"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0011.jpg"
                alt="10"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0012.jpg"
                alt="11"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0013.jpg"
                alt="12"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0014.jpg"
                alt="13"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0015.jpg"
                alt="14"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0016.jpg"
                alt="15"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0017.jpg"
                alt="16"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0018.jpg"
                alt="17"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0019.jpg"
                alt="18"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0020.jpg"
                alt="19"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0023.jpg"
                alt="20"
              />
              <img
                className="homePatnersImg"
                src="/img/IMG-20240404-WA0024.jpg"
                alt="21"
              />
              <img
                className="homePatnersImg"
                src="/img/1713431575791.jpg"
                alt="22"
              />
              <img
                className="homePatnersImg"
                src="/img/1713431575778.jpg"
                alt="23"
              />
              <img
                className="homePatnersImg"
                src="/img/1713431575784.jpg"
                alt="24"
              />
            </div>
          </marquee>
        </div>
      </section>
    </>
  );
}

export default HomePatners;
