import React from "react";
import { Carousel } from "react-bootstrap";

import "./HomeCarousel.css";

function HomeCarousel() {
  return (
    <>
      <section id="homeCarousel">
        <div className="homeCarouselContainer">
          <Carousel
            fade={true}
            indicators={false}
            controls={false}
            className="homeCarousel"
          >
            <Carousel.Item className="homeCarouselItem">
              <img
                className="homeCarouselDoorImg"
                src="/img/1713112315681(cropped).jpg"
                alt="Slider 1"
              />
              <img
                className="homeCarouselDoorImg"
                src="/img/IMG-20240213-WA0004.jpg"
                alt="Slider 1"
              />
              <div className="homeCarouselWriteup">
                <span className="homeCarouselWriteupTitle">
                  World Class Doors
                </span>
              </div>
            </Carousel.Item>
            <Carousel.Item className="homeCarouselItem">
              <img
                className="homeCarouselItemImg"
                src="/img/1712132877564.jpg"
                alt="Slider 2"
              />
              <div className="homeCarouselWriteup">
                <span className="homeCarouselWriteupTitle">Kitchens</span>
              </div>
            </Carousel.Item>
            <Carousel.Item className="homeCarouselItem">
              <img
                className="homeCarouselItemImg"
                src="/img/1712132877592.jpg"
                alt="Slider 3"
              />
              <div className="homeCarouselWriteup">
                <span className="homeCarouselWriteupTitle">Wardrobes</span>
              </div>
            </Carousel.Item>
            <Carousel.Item className="homeCarouselItem">
              <img
                className="homeCarouselItemImg"
                src="/img/ironmongeryBanner.jpg"
                alt="Slider 4"
              />
              <div className="homeCarouselWriteup">
                <span className="homeCarouselWriteupTitle">Ironmongery</span>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </>
  );
}

export default HomeCarousel;
