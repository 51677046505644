import React from "react";
import "./Wardrobes.css";
import WardrobeCard from "./WardrobeCard";

function Wardrobes() {
  return (
    <>
      <section id="wardrobe">
        <div className="wardrobeContainer">
          <div className="wardrobeBanner" />
          <div className="wardrobeTitle">
            <span>WARDROBES</span>
          </div>
          <WardrobeCard />
        </div>
      </section>
    </>
  );
}

export default Wardrobes;
