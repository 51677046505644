import React from "react";
import "./Kitchens.css";
import KitchenCard from "./KitchensCard";

function Kitchens() {
  return (
    <>
      <section id="kitchen">
        <div className="kitchenContainer">
          <div className="kitchenBanner" />
          <div className="kitchenTitle">
            <span>KITCHENS</span>
          </div>
          <KitchenCard />
        </div>
      </section>
    </>
  );
}

export default Kitchens;
