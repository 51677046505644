import React from "react";

function Handles() {
  return (
    <>
      <section id="handles">
        <div className="handlesContainer">
          <div className="cardLeftContainer">
            <img
              className="cardLeftTopImg"
              src="/img/IMG-20240229-WA0003.jpg"
              alt="1"
            />
            <div className="cardLine" />
            <img
              className="cardLeftImg"
              src="/img/IMG-20240229-WA0004.jpg"
              alt="2"
            />
            <div className="cardLine" />
            <img
              className="cardLeftImg"
              src="/img/IMG-20240229-WA0009.jpg"
              alt="2"
            />
            <div className="cardLine" />
            <img
              className="cardLeftImg"
              src="/img/IMG-20240229-WA0010.jpg"
              alt="2"
            />
            <div className="cardLine" />
            <img
              className="cardLeftImg"
              src="/img/IMG-20240229-WA0014.jpg"
              alt="2"
            />
            <div className="cardLine" />
          </div>
          <div className="cardRightContainer">
            <div className="cardLineTop" />
            <img
              className="cardRightImg"
              src="/img/IMG-20240229-WA0007.jpg"
              alt="5"
            />
            <div className="cardLine" />
            <img
              className="cardRightImg"
              src="/img/IMG-20240229-WA0008.jpg"
              alt="6"
            />
            <div className="cardLine" />
            <img
              className="cardRightImg"
              src="/img/IMG-20240229-WA0012.jpg"
              alt="6"
            />
            <div className="cardLine" />
            <img
              className="cardRightImg"
              src="/img/IMG-20240229-WA0013.jpg"
              alt="6"
            />
            <div className="cardLine" />
            <img
              className="cardRightImg"
              src="/img/IMG-20240229-WA0015.jpg"
              alt="6"
            />
            <div className="cardLine" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Handles;
