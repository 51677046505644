import React from "react";

function WardrobeCard() {
  return (
    <>
      <section id="wardrobes">
        <div className="wardrobesContainer">
          <div className="cardLeftContainer">
            <img
              className="cardLeftTopImg"
              src="/img/1712132877592.jpg"
              alt="1"
            />
            <div className="cardContent">
              <span>Krystal</span>
            </div>
            <div className="cardLine" />
            <img className="cardLeftImg" src="/img/1712132791857.jpg" alt="2" />
            <div className="cardContent">
              <span>Ziago</span>
            </div>
            <div className="cardLine" />
          </div>
          <div className="cardRightContainer">
            <div className="cardLineTop" />
            <div className="cardContent">
              <span>Zabaka</span>
            </div>
            <img
              className="cardRightImg"
              src="/img/1712132877587.jpg"
              alt="2"
            />
            <div className="cardLine" />
            <div className="cardContent">
              <span>Largo</span>
            </div>
            <img className="cardRightImg" src="/img/Wardrobe1.png" alt="6" />
            <div className="cardLine" />
          </div>
        </div>
      </section>
    </>
  );
}

export default WardrobeCard;
