import React from "react";
import "./Contactus.css";

function Contactus() {
  return (
    <>
      <section id="contactus">
        <div className="contactusContainer">
          <div className="mapContainer">
            <iframe
              className="map"
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6959800942254!2d3.476230075893013!3d6.433084593558067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5b674de99e1%3A0x2814ab6aec0fba9c!2sBM%20Direct!5e0!3m2!1sen!2sng!4v1703493477483!5m2!1sen!2sng"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="formContainer">
            <div className="formWrap">
              <form
                action="https://formsubmit.co/sales@bmdirect.biz"
                title="Enquiry"
                method="post"
              >
                <input
                  type="text"
                  id="firstName"
                  name="First Name"
                  placeholder="First Name"
                  required
                />
                <input
                  type="text"
                  id="lastName"
                  name="Last Name"
                  placeholder="Last Name"
                  required
                />
                <input
                  type="email"
                  id="email"
                  name="Email"
                  placeholder="Email"
                  required
                />
                <input
                  type="tel"
                  id="phoneNumber"
                  name="Phone Number"
                  placeholder="Phone Number"
                  required
                />
                <textarea
                  name="Message"
                  id="message"
                  cols="30"
                  rows="12"
                  placeholder="Message"
                ></textarea>
                <button htmlFor="check" type="submit" id="submit">
                  <h4 id="submitTxt">Submit</h4>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactus;
