import React from "react";
import "./Doors.css";

function ExternalDoors() {
  return (
    <>
      <section id="doors">
        <div className="doorsContainer">
          <div className="cardLeftContainer">
            <img
              className="cardLeftTopImg"
              src="/img/IMG-20240403-WA0002.jpg"
              alt="1"
            />
            <div className="cardContent">
              <span>RADIAL PATTERNED TIMBER SECURITY DOOR</span>
              <p>
                Radial patterned distressed timber security door with steel
                substructure.
              </p>
            </div>
            <div className="cardLine" />
            <img
              className="cardLeftImg"
              src="/img/IMG-20240403-WA0005.jpg"
              alt="2"
            />

            <div className="cardContent">
              <span>TOUGHENED GLASS SECURITY DOOR</span>
              <p>Tinted glazed panel security door with steel substructure.</p>
            </div>
            <div className="cardLine" />
          </div>
          <div className="cardRightContainer">
            <div className="cardLineTop" />
            <div className="cardContent">
              <span>COMPOSITE METAL FACED SECURITY DOOR</span>
              <p>Composite metal face security door with steel substructure.</p>
            </div>
            <img
              className="cardRightImg"
              src="/img/IMG-20240403-WA0006.jpg"
              alt="5"
            />
            <div className="cardLine" />
            <div className="cardContent">
              <span>TOUGHENED GLASS SECURITY DOOR</span>
              <p>Tinted glazed security door with steel substructure.</p>
            </div>
            <img
              className="cardRightImg"
              src="/img/1713112315672.jpg"
              alt="6"
            />
            <div className="cardLine" />
            <div className="cardContent">
              <span>TOUGHENED GLASS SECURITY DOOR</span>
              <p>Tinted glazed security door with steel substructure.</p>
            </div>
            <img
              className="cardRightImg"
              src="/img/1713112315681(cropped).jpg"
              alt="6"
            />
            <div className="cardLine" />
          </div>
        </div>
      </section>
    </>
  );
}

export default ExternalDoors;
