import React, { useRef } from "react";
import { motion as m } from "framer-motion";
import { NavLink } from "react-router-dom";
import "./Nav.css";

function Nav() {
  window.addEventListener("scroll", function () {
    var nav = document.getElementById("nav");
    nav.classList.toggle("sticky", window.scrollY > 0);
  });

  const mobileNavRef = useRef();

  const showMobileNav = () => {
    mobileNavRef.current.classList.toggle("responsive_mobilenav");
  };

  return (
    <>
      <section id="mobileNavBar">
        <NavLink to="/" className="mobileNavLogoWrap">
          <img
            className="mobileNavLogo"
            src="/img/bmdirectLogo.png"
            alt="BMDirect Nav Logo"
          />
        </NavLink>
        <button className="mobileNavToggle" onClick={showMobileNav}>
          <i className="fa-solid fa-bars" />
        </button>
        <div className="mobileNavLinksContainer" ref={mobileNavRef}>
          <div className="mobileNavLinksContent">
            <NavLink to="/" className="mobileLogoNavLinkWrap">
              <img
                className="mobileLogoNavLink"
                src="/img/bmdirectLogo.png"
                alt="BMDirect Nav Logo"
              />
            </NavLink>
            <li>
              <NavLink className="topMobileNavLinks" to="/projects">
                Projects
              </NavLink>
            </li>
            <div className="mobileNavLinksContentContainer">
              <div className="mobileNavContainer">
                <li>
                  <NavLink className="mobileNavLinks" to="/doors">
                    Doors
                  </NavLink>
                </li>
                <li>
                  <NavLink className="mobileNavLinks" to="/kitchens">
                    Kitchens
                  </NavLink>
                </li>
                <li>
                  <NavLink className="mobileNavLinks" to="/aboutus">
                    About Us
                  </NavLink>
                </li>
              </div>
              <div className="mobileNavContainer">
                <li>
                  <NavLink className="mobileNavLinks" to="/wardrobes">
                    Wardrobes
                  </NavLink>
                </li>
                <li>
                  <NavLink className="mobileNavLinks" to="/ironmongery">
                    Ironmongery
                  </NavLink>
                </li>
                <li>
                  <NavLink className="mobileNavLinks" to="/contactus">
                    Contact Us
                  </NavLink>
                </li>
              </div>
            </div>
            <li className="mobileContactusBtnContainer">
              <NavLink to="/contactus" className="mobileContactusBtn">
                Schedule Consultation
              </NavLink>
            </li>
          </div>
        </div>
      </section>
      <m.section
        initial={{ top: -100 }}
        animate={{ top: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        id="nav"
      >
        <div className="navContainer">
          <NavLink to="/" className="pointedgeLogoWrap">
            <img
              className="pointedgeLogo"
              src="/img/bmdirectLogo.png"
              alt="BMDirect Nav Logo"
            />
          </NavLink>
          <ul className="navMenu">
            <li>
              <NavLink to="/projects" id="link" className="navMenuLink">
                Projects
              </NavLink>
            </li>
            <li id="link" className="dropdownNavLink">
              Gallery&nbsp;
              <i className="fa-solid fa-caret-down"></i>
              <ul className="navLinkContainer">
                <li className="navLinkWrap">
                  <NavLink to="/doors" id="link" className="navLink">
                    Doors
                  </NavLink>
                  <NavLink to="/kitchens" id="link" className="navLink">
                    Kitchens
                  </NavLink>
                  <NavLink to="/wardrobes" id="link" className="navLink">
                    Wardrobes
                  </NavLink>
                  <NavLink to="/ironmongery" id="link" className="navLink">
                    Ironmongery
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/aboutus" id="link" className="navMenuLink">
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/contactus" id="link" className="navMenuLink">
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className="contactusBtnContainer">
            <NavLink to="/contactus" className="contactusBtn">
              Schedule Consultation
            </NavLink>
          </div>
        </div>
      </m.section>
    </>
  );
}

export default Nav;
